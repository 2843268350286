import { useState } from 'react';
import "./Features.css"
import Feature from './Feature/Feature';


function Features() {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);
    window.addEventListener('resize', () => {
        // viewport = document.documentElement.clientWidth;
        setViewport(document.documentElement.clientWidth);
    });
    let viewportType = `${viewport >= 992 ? "desktop" : "mobile"}/`;

    let containerClasses = `section feature-container ${viewport >= 992 ? "desktop" : "mobile"}`;
    let headerClasses = `header ${viewport >= 992 ? "desktop" : "mobile"}`;
    let featureClasses = `row features ${viewport >= 992 ? "desktop" : "mobile"}`;
    let circleClasses = `feature-red-circle ${viewport >= 992 ? "desktop" : "mobile"}`
    let mockupClasses = `mockup ${viewport >= 992 ? "desktop" : "mobile"}`;



    return <div className={containerClasses} id="features">
        <h2 className={headerClasses}>Features</h2>
        <div className="container-fluid feature-outer">
            <div className={featureClasses}>
                <Feature icon={`./images/features/${viewportType}AI-powered_icon.svg`} text={"AI-powered software system for automated scores’ recording"} />
                <Feature icon={`./images/features/${viewportType}Data transfer_icon.svg`} text={"Data transfer to cloud and real time reporting to mobile app (iOS and Android)"} />
                <Feature icon={`./images/features/${viewportType}Statistics based_icon.svg`} text={"Statistics based analysis of results and progress tracking for athletes and coaches"} />
                <Feature icon={`./images/features/${viewportType}Unique design_icon.svg`} text={"Unique design for personal use"} />
                <Feature icon={`./images/features/${viewportType}Leaderboard_icon.svg`} text={"Local and global leaderboard availability"} />
                <Feature icon={`./images/features/${viewportType}Social media_icon.svg`} text={"Social media sharing functionality"} />
            </div>
        </div>
        <div className={circleClasses}> </div>
        <img className={mockupClasses} src="./images/features/Transparent mockup.svg" />
    </div>
}


export default Features;