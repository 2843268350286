import { useState } from 'react';
import "./Button.css";


function Button(params) {
   const [viewport, setViewport] = useState(document.documentElement.clientWidth);
   window.addEventListener('resize', () => {
      //  viewport = document.documentElement.clientWidth;
       setViewport(document.documentElement.clientWidth);
   });
   let buttonClasses = `store-button ${viewport >= 992 ? "desktop" : "mobile"}`;
   let iconClasses = `store-icon ${viewport >= 992 ? "desktop" : "mobile"}`;
   let downloadClases = `download-text ${viewport >= 992 ? "desktop" : "mobile"}`;
   let textClasses = `text ${viewport >= 992 ? "desktop" : "mobile"}`;
   let storeClases = `text store ${viewport >= 992 ? "desktop" : "mobile"}`;
   let qrClasses = `qr ${viewport >= 992 ? "desktop" : "mobile"}`;


   return <button className={buttonClasses}>
      <img className={iconClasses} src={params.icon} alt="" />
      <span className={downloadClases}>
         <span className={textClasses}>{params.accompanyingText}</span>
         <span className={storeClases}>{params.storeName}</span>
      </span>
      <img className = {qrClasses} src={params.qr} alt="" />

   </button>


}

export default Button;