import { useState } from 'react';
import "./DataLine.css"



function DataLine(params) {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);
    window.addEventListener('resize', () => {
        // viewport = document.documentElement.clientWidth;
        setViewport(document.documentElement.clientWidth);
    });
    let numberClasses = `number ${viewport >= 992 ? "desktop" : "mobile"}`;
    let numClasses = `num ${viewport >= 992 ? "desktop" : "mobile"}`;
    let textClasses = `text ${viewport >= 992 ? "desktop" : "mobile"}`;

    return <div>
        <div className={numberClasses}>
            <span className={numClasses}>{params.number}</span>
        </div>
        <div className={textClasses}>{params.text}</div>
    </div>
}

export default DataLine;