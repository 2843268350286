import { useState } from 'react';
import "./GetInTouch.css";
import InputField from './InputField/InputField';
import ContactLink from './ContactLink/ContactLink';
import { parsePhoneNumberFromString } from 'libphonenumber-js';



function GetInTouch() {

    const [viewport, setViewport] = useState(document.documentElement.clientWidth);
    window.addEventListener('resize', () => {
        // viewport = document.documentElement.clientWidth;
        setViewport(document.documentElement.clientWidth);
    });

    const [inputText, setInputText] = useState("");

    const [input_email, setInput_email] = useState("");
    const [input_name, setInput_name] = useState("");
    const [input_phone, setInput_phone] = useState("");


    document.addEventListener('invalid', ((e) => {
        return (e) => {
            //prevent the browser from showing default error bubble / hint
            e.preventDefault();
        };
    })(), true);

    function validityMessageShow(e) {

        let validityMessage = (e?.target.nextElementSibling).nextElementSibling;
        validityMessage.innerHTML = "Please fill out this field";

        validityMessage.style.display = "block";

    }

    function validityMessageHide(e) {
        let validityMessage = (e?.target.nextElementSibling).nextElementSibling;
        validityMessage.innerHTML = "";
        validityMessage.style.display = "none";
    }

    function phoneValidation() {
        const phoneInput = document.getElementById("phone");
        let validityMessage = (phoneInput.nextElementSibling).nextElementSibling;
        const phoneNumber = parsePhoneNumberFromString(phoneInput.value);
        if ((phoneNumber && phoneNumber.isValid)) {
            return true;
        } else {
            validityMessage.innerHTML = "Please enter a valid phone number";
            validityMessage.style.display = "block";
            return false;
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (!phoneValidation()) {
            return;
        }

        let inputs = document.getElementsByClassName("inputField");

        Array.from(inputs).forEach(input => {
            input.value = "";
        });
        setInputText("");
        setInput_email("");
        setInput_name("");
        setInput_phone("");

        let validityMessages = document.getElementsByClassName("validity-message");
        Array.from(validityMessages).map((message) => {
            message.innerHTML = "";
            message.style.display = "none";
        })

        document.getElementById("text-message").style.height = ""  // reset size 
    }

    function inputBlur(e) {
        if (e.target.value.trim() === "") {
            e.target.value = ""
            e.target.style.height = "";
        }
        setInputText(e.target.value);
    }

    function textMessageExpand(e) {
        validityMessageHide(e);
        e.target.style.height = 'auto'; // Reset the height;
        e.target.style.height = (e.target.scrollHeight) + 'px'; // Set to the scroll height
    }


    function addPhonePlusSymbol(e) {
        if (e.target.value.indexOf('+') !== 0) {
            e.target.value = '+' + e.target.value.replace(/\+/g, '');
        }
    };

    function removePhonePlusSymbol(e) {
        if (e.target.value === "+" || e.target.value.trim() === "+") {
            e.target.value = "";
            setInput_phone("");
        }
    };
    function handleFormFocus(e) {
        const inputs = document.querySelectorAll('input, textarea'); // Include textareas

        inputs.forEach(input => {
            input.addEventListener('input', () => {
                if (input.matches(':-webkit-autofill') || input.matches(':autofill')) {

                    if (input.id === "email") {
                        setInput_email("input.value")
                    } else if (input.id === "name") {
                        setInput_name("input.value")
                    } else if (input.id === "phone") {
                        setInput_phone("input.value")
                    } else if (input.id === "text-message") {
                        setInputText("input.value")
                    }
                }
            })


        })


    }
    

    let containerClasses = `section getInTouch-container ${viewport >= 992 ? "desktop" : "mobile"}`;

    let headerClasses = `header ${viewport >= 992 ? "desktop" : "mobile"}`;

    let innerClasses = `container-fluid inn-container ${viewport >= 992 ? "desktop" : "mobile"}`;
    let innerRowClasses = `row inn-row ${viewport >= 992 ? "desktop" : "mobile"}`;
    let formClasses = `form col12 col-lg-6 ${viewport >= 992 ? "desktop" : "mobile"}`;
    let buttonClasses = `submit-button ${viewport >= 992 ? "desktop" : "mobile"}`

    let contactClasses = `contact col12 col-lg-6 ${viewport >= 992 ? "desktop" : "mobile"}`;
    let bigLogoClasses = `big-logo ${viewport >= 992 ? "desktop" : "mobile"}`;
    let headClasses = `head contact-text ${viewport >= 992 ? "desktop" : "mobile"}`;
    let validityClasses = `validity-message  validity-message-textarea ${viewport >= 992 ? "desktop" : "mobile"}`;
    let textareaClasses = `inputField  ${inputText !== "" ? "input-present" : ""} ${viewport >= 992 ? "desktop" : "mobile"}`
    let placeholderClasses = `placeholder ${inputText !== "" ? "input-present" : ""} ${viewport >= 992 ? "desktop" : "mobile"} }`
    let decoreClasses = `decore  ${viewport >= 992 ? "desktop" : "mobile"}`;


    return <div className={containerClasses} id='getInTouch'>
        <img className={bigLogoClasses} src="./images/getInTouch/ArmorX logo.svg" alt="" />
        <h2 className={headerClasses}>Get in touch</h2>
        <div className={innerClasses}>
            <div className={innerRowClasses}>
                <form className={formClasses} onSubmit={handleSubmit} id="contact-form" action='' method='' onFocus={handleFormFocus}>
                    <InputField type={`email`} placeholder={`Email`} id={"email"} setInput={setInput_email} inputText={input_email} />
                    <InputField type={`text`} placeholder={`Name`} id={"name"} setInput={setInput_name} inputText={input_name} />
                    <InputField type={`tel`} placeholder={`Phone Number`} id={"phone"} setInput={setInput_phone} inputText={input_phone} addPlusSymbol={addPhonePlusSymbol} removePlusSymbol={removePhonePlusSymbol} />
                    <div className='group'>
                        <textarea
                            className={textareaClasses}
                            id="text-message"
                            onInput={textMessageExpand}
                            onInvalid={validityMessageShow}
                            onBlur={inputBlur}
                            // onLoad={autofillAdjusting}
                            required
                        />
                        <label className={placeholderClasses}>Text Message</label>
                        <span className={validityClasses}></span>
                    </div>
                    <span className={validityClasses}></span>
                    <input className={buttonClasses} type="submit" value="Submit" onClick={phoneValidation} />
                </form>

                <div className={contactClasses}>
                    <div className={headClasses}>
                        <p>Have questions or need assistance?&nbsp;</p>
                        <p>Get in touch with us today!</p>
                    </div>

                    <ContactLink href={"tel:+374 98 910 375"} icon={"./images/getInTouch/Phone.svg"} text={"+374 98 910 375"} />

                    <ContactLink href={"mailto: info@improvis.ai"} icon={"./images/getInTouch/Mail.svg"} text={"info@improvismail.com"} />

                    <ContactLink href={"https://maps.app.goo.gl/8hEH6cSWkwh1XQmeA"} icon={"./images/getInTouch/Location.svg"} text={"58 Kamarak st., Yerevan, Armenia"} />
                    <div className="bottom">
                        <hr className={decoreClasses} />
                        <span className='icon-group'>
                            <ContactLink href={"https://am.linkedin.com/company/improvis"} icon={"./images/getInTouch/Linkedin.svg"} text={""} className={"rounded"} name={"Linkedin"} animated={true} />
                            <ContactLink href={"https://www.facebook.com/improvisteam/"} icon={"./images/getInTouch/Facebook.svg"} text={""} className={"rounded"} name={"Facebook"} animated={true} />
                            <ContactLink href={"https://improvis.ai/home"} icon={"./images/getInTouch/Improvis.svg"} text={""} className={"rounded"} name={"Improvis"} animated={true} />
                        </span>
                        <p className='copyright contact-text'>© 2024 Improvis Design. All Rights Reserved.</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
}


export default GetInTouch;