import { useState } from 'react';
import "./ExploreTheProcess.css";

function ExploreTheProcess() {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);
    window.addEventListener('resize', () => {
        // viewport = document.documentElement.clientWidth;
        setViewport(document.documentElement.clientWidth);
    });
    let containerClasses = `section explore-container anchor-1 ${viewport >= 992 ? "desktop" : "mobile"}`;
    let videoClasses = `video-box container-fluid ${viewport >= 992 ? "desktop" : "mobile"}`;
    let headingClasses = `header ${viewport >= 992 ? "desktop" : "mobile"}`;

    
    return <div className = {containerClasses} id='explore' >
        <h2 className={headingClasses}>Explore the Process</h2>
        <div className={videoClasses}>
            <iframe className="responsive-iframe"
                // src="https://www.youtube.com/embed/uCABz-Yv04Q?modestbranding=1&controls=0&rel=0&showinfo=0&autoplay=1&loop=1&mute=1&playlist=uCABz-Yv04Q"
                poster="/image/ph_video-fill.svg"
            ></iframe>
        </div>
    </div>

}

export default ExploreTheProcess;