import React, { useState, useEffect } from 'react';
import "./NavBar.css";


function NavBar() {

    // const [navLinks, setNavLinks] = useState([]);

    useEffect(() => {
        const sections = document.querySelectorAll(".section");
        const links = document.querySelectorAll(".nav-link");

        // setNavLinks(links);

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const targetId = entry.target.id;
                    links.forEach(link => {
                        const isActive = link.getAttribute("href").substring(1) === targetId;
                        link.classList.toggle("active", isActive);
                        if (isActive) {
                            link.focus();
                        } else {
                            link.blur();
                        }
                    });
                }
            });
        }, { threshold: 0.1 });

        sections.forEach(section => observer.observe(section));

        return () => {
            // Clean up the observer on component unmount
            sections.forEach(section => observer.unobserve(section));
        };
    }, []);




    const [viewport, setViewport] = useState(document.documentElement.clientWidth);
    window.addEventListener('resize', () => {
        // viewport = document.documentElement.clientWidth;
        setViewport(document.documentElement.clientWidth);
    });

    let navBarClasses = `navbar navbar-expand-lg ${viewport >= 992 ? "desktop sticky-top" : "mobile"}`;
    let linkClasses = `navbar-brand .navbar.mobile ${viewport >= 992 ? "desktop" : "mobile"}`;
    let logoClasses = `logo ${viewport >= 992 ? "desktop" : "mobile"}`;

    return <nav className={navBarClasses}>
        <a className={linkClasses} href="#">
            <img className={logoClasses} src="./images/navbar/Logo.svg" alt="Your Logo" />
        </a>
        <div className="container-fluid">
            <div className="col-lg-2"></div>
            <div className="collapse navbar-collapse col-lg-10" id="navbarNav">
                <ul className="navbar-nav">
                    <li style={{display:"none"}}>
                        <a  href="#banner" className="nav-link"> </a>  {/* used in useEffect function */}
                    </li>
                    <li className="nav-item">
                        <a className="nav-link rounded" href="#explore">
                            Explore the Process
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link rounded" href="#features">
                            Features
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link rounded" href="#dataflow">
                            Dataflow
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link rounded" href="#getInTouch">
                            Get in Touch
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

}


export default NavBar;
