import { useState } from 'react';
import "./Feature.css"

function Feature(params) {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);
    window.addEventListener('resize', () => {
        // viewport = document.documentElement.clientWidth;
        setViewport(document.documentElement.clientWidth);
    });
    let featureClasses = `col-6 col-lg-4 feature ${viewport >= 992 ? "desktop" : "mobile"}`;
    let cardClasses = `feature-card ${viewport >= 992 ? "desktop" : "mobile"}`;
    let iconClasses = `feature-icon ${viewport >= 992 ? "desktop" : "mobile"}`
    let textClasses = `feature-text ${viewport >= 992 ? "desktop" : "mobile"}`;

    return <div className={featureClasses}>
        <div className={cardClasses} >
            <img className={iconClasses} src={params.icon}></img>
            <span className={textClasses}>
                {params.text}
            </span>
        </div>
    </div>
}

export default Feature;