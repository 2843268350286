import NavBar from './Components/NavBar/NavBar';
import Banner from './Components/Banner/Banner';
import ExploreTheProcess from './Components/ExploreTheProcess/ExploreTheProcess';
import Features from './Components/Features/Features';
import GetInTouch from './Components/GetInTouch/GetInTouch';
import Dataflow from './Components/Dataflow/Dataflow';


function App() {
  return (
    <>
      <NavBar />
      <Banner />
      <ExploreTheProcess />
      <Features />
      <Dataflow/>
      <GetInTouch />
    </>
  );
}

export default App;
