import { useState } from 'react';
import "./Banner.css"
import Button from "./Buttons/Button";

function Banner() {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);
    window.addEventListener('resize', () => {
        // viewport = document.documentElement.clientWidth;
        setViewport( document.documentElement.clientWidth);
    });
    let bannerClasses = `section banner ${viewport >= 992 ? "desktop" : "mobile"}`;
    let containerClasses = `button-container ${viewport >= 992 ? "desktop" : "mobile"}`;
    let introductionClasses = `introduction ${viewport >= 992 ? "desktop" : "mobile"}`;
    let buttonsClasses = `buttons ${viewport >= 992 ? "desktop" : "mobile"}`;

    return <div id='banner' className={bannerClasses} >
        < div className={containerClasses}>
            <p className={introductionClasses}>Exploiting advanced AI and cutting-edge technology, we offer a cost-effective, fully automated shooting stand solution with cloud infrastructure and a mobile app, designed to support associations, athletes, coaches, and amateurs in mastering shooting sports.</p>
            <div className={buttonsClasses}>
                <Button icon={"./images/store-buttons/bi_apple.svg"} storeName={"App Store"} accompanyingText={"Download on the"} qr={"./images/store-buttons/QR.svg"} />
                <Button icon={"./images/store-buttons/mage_playstore.svg"} storeName={"Play Store"} accompanyingText={"Download on the"} qr={"./images/store-buttons/QR.svg"} />
            </div>
        </div>
    </div>
}

export default Banner;